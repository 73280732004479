import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

// Components 
import { Flag } from '../components'

const Link = styled.div`
    transition: 0.2s linear;
    cursor: pointer;
    font-weight: bold;

    :hover& {
        color: rgb( 100, 100, 250 )
    }
`

const Login = props => {

    return ( 
        <Layout
            title = { 'Login / Register' }
            index
            discription = { 'Siu Sons customers login or register here' }
        >
            <div className = 'site-About' >
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh'
                    }}
                >
                    <h1>Login</h1>
                    <div style = {{ color: 'white' }}>For Siu &amp; Sons customers, Login here</div>
                    <div style = {{ color: 'white' }}>Siu Sons customers login here and start shopping</div>
                    <br/>
                    <br/>
                    <span>For Canadian Customer &nbsp;<Flag country = { 'ca' } /></span>
                    <br/>
                    <RedirectLink country = { 'ca' }/>
                    <br/>
                    <br/>
                    <span>For International Customer &nbsp;<Flag country = { 'us' } /></span>
                    <br/>
                    <RedirectLink country = { 'us' }/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Register</h1>
                    <span>For Canadian Customer &nbsp;<Flag country = { 'ca' } /></span>
                    <br/>
                    <a href = 'https://canada.siunsons.com/pages/register'>Click here to register an account</a>
                    <br/>
                    <br/>
                    <span>For International Customer  &nbsp;<Flag country = { 'us' } /></span>
                    <br/>
                    <a href = 'https://international.siunsons.com/pages/register'>Click here to register an account</a>
                    <br/>
                </div>
            </div>
        </Layout>
    )
}
export default Login

const RedirectLink = props => {

    const handleClick = path => {
        window.location.href = path
    }

    return (
      <StaticQuery
        query = {
            graphql`
                query OutboundLinkQuery {
                    datoCmsOutbound {
                        canadaWebstoreUrl
                        internationalWebstoreUrl
                    }
                }
            `
        }
        render = { data => {
            if ( props.country === 'ca' ) return <Link onClick = { () => handleClick( data.datoCmsOutbound.canadaWebstoreUrl ) } >Click here</Link>
            if ( props.country === 'us' ) return <Link onClick = { () => handleClick( data.datoCmsOutbound.internationalWebstoreUrl ) } >Click here</Link>
            return <div/>
        }}
      />
    )
}